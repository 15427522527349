<template>
  <b-overlay
    :show="isLoading"
    class="pb-4 wallet-family-table"
  >
    <!-- Create New Percent Based Wallet -->
    <create-split-wallet
      v-if="createWallet"
      :user-type="userType"
      :is-default-wallet="false"
      :used-banks="usedBanks"
      :family-type="familyTypeToCreate"
      @wallet-config-success="() => { createWallet = false; getWallet() }"
      @back="() => createWallet = false"
    />

    <!-- Wallets List -->
    <div v-else>
      <div
        class="d-flex align-items-center w-100"
        :class="IS_MOBILE() ? 'justify-content-between' : 'justify-content-end'"
      >
        <!-- total balance -->
        <h4
          class="cursor-pointer"
          @click="showBalance = !showBalance"
        >
          <span class="mr-1">{{ $t('Total Balance') }}:</span>
          <span v-if="showBalance">{{ formatAmount(totalBalance) }}</span>
          <span v-else>XXX.XX</span>
          <feather-icon
            class="ml-1 cursor-pointer"
            :icon="showBalance ? 'EyeOffIcon' : 'EyeIcon'"
          />
        </h4>

        <b-button
          id="tour-family-statement"
          v-b-tooltip.hover="$t('View Statement')"
          variant="wz-teal"
          class="ml-2 mb-1"
          @click="$router.push({ name: 'wallet-statement', params: { userType, walletFamily: wallet.uuid, company, overall: true } })"
        >
          <i class="fas fa-file-lines" />
        </b-button>
      </div>

      <b-overlay
        class="wallet-table table-responsive"
        :show="isLoading"
      >
        <!-- Table Headers -->
        <div class="table-header d-flex align-items-center">
          <div
            v-for="field in fields"
            :key="field.key"
            :class="field.classes"
            style="min-width: 240px;"
          >
            <h6
              v-if="field.key !== 'actions'"
              class="mb-0"
            >
              {{ field.label }}
            </h6>
            <b-dropdown
              v-else
              id="tour-add-wallet"
              variant="link"
              toggle-class="add-account-btn table-text text-decoration-none p-0"
              right
              no-caret
              @toggle="showingDropdown = !showingDropdown"
            >
              <template #button-content>
                <div
                  id="add-new-wallet-btn"
                  class="dropdown-btn d-flex align-items-center"
                >
                  <feather-icon icon="PlusIcon" />
                  <p
                    class="mb-0"
                    :style="'margin-left: 4px;'"
                  >
                    New Account
                  </p>
                  <feather-icon :icon="showingDropdown ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                </div>
              </template>
              <!-- Add Percent Based -->
              <b-dropdown-item
                class="text-capitalize"
                @click="() => { familyTypeToCreate = 'PERCENTBASED'; createWallet = true }"
              >
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="PercentIcon"
                    class="mr-1"
                    size="26"
                  />
                  <div class="w-25">
                    <p class="mb-0 font-weight-bold">
                      Add Percent Based Account
                    </p>
                    <small class="text-lowercase">
                      allows you to make alt-accounts with certain proportion
                    </small>
                  </div>
                </div>
              </b-dropdown-item>

              <!-- Add Open Wallet -->
              <b-dropdown-item
                class="text-capitalize"
                @click="() => { familyTypeToCreate = 'OPENWALLET'; createWallet = true }"
              >
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="DiscIcon"
                    class="mr-1"
                    size="26"
                  />
                  <div class="w-25">
                    <p class="mb-0 font-weight-bold">
                      Add Open Account
                    </p>
                    <small class="text-lowercase">
                      allows you to make alt-accounts without any proportions
                    </small>
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <!-- Table Body -->

        <!-- Mobile View without Draggable -->
        <div v-if="walletFamily.length && IS_MOBILE()">
          <div
            v-for="family, index in walletFamily"
            :key="family.uuid"
            class="cursor-move"
          >
            <div
              class="d-flex align-items-center cursor-pointer table-row"
              :class="{striped: index%2}"
              @mouseenter="hoveredFamily = family.uuid"
              @mouseleave="hoveredFamily = null"
              @click="detailsShowingFamily = detailsShowingFamily === family.uuid ? null : family.uuid"
            >
              <!-- Alias -->
              <div
                class="col-5 d-flex align-items-center ml-1 py-2"
                style="min-width: 240px;"
              >
                <!-- Drag Icon -->
                <div class="drag-btn pr-1 d-none d-md-block">
                  <i
                    v-if="hoveredFamily === family.uuid && walletFamily.length > 1"
                    class="fas fa-grip-vertical cursor-move"
                  />
                </div>
                <!-- Alias Content -->
                <i
                  v-if="family.isDefault"
                  v-b-tooltip.hover="$t('Payroll Accounts')"
                  class="fa fa-cash-register"
                />
                <div>
                  <span class="px-1">{{ family.description }}</span>
                  <feather-icon
                    v-if="detailsShowingFamily === family.uuid"
                    size="20"
                    icon="ChevronUpIcon"
                  />
                  <feather-icon
                    v-else
                    size="20"
                    icon="ChevronDownIcon"
                  />
                  <!-- Shared Wallet Info -->
                  <div
                    v-if="family.shares.length && family.shares[0].owner && isWalletShared(family.shares[0], userType)"
                    class="ml-1"
                  >
                    <feather-icon icon="LinkIcon" />
                    <small class="small">
                      {{ $t('Shared by') }}
                      <i
                        class="ml-2"
                        :class="family.shares.length && family.shares[0].owner.__typename === 'User' ? 'fas fa-user' : 'fas fa-building'"
                      />
                      <span v-if="family.shares.length">
                        {{ family.shares[0].owner.name }}
                      </span>
                    </small>

                    <b-badge
                      variant="wz-teal"
                      class="d-block"
                    >
                      <i
                        class="fa"
                        :class="GET_SHARED_DIRECTION(family.shares[0].shareDirection, {icon: true})"
                      />
                      {{ family.shares[0].shareDirection ? GET_SHARED_DIRECTION(family.shares[0].shareDirection) : '' }}
                    </b-badge>
                  </div>
                </div>
              </div>

              <!-- Wallet Type -->
              <div class="col-4 px-0">
                <span v-if="isWalletShared(family, userType, 'family')">
                  {{ $t('Shared Account') }}
                </span>
                <span v-else>
                  {{ family.familyType === 'OPENWALLET' ? $t('Open Account') : $t('Percent Based Account') }}
                </span>
              </div>

              <!-- Total Balance -->
              <div
                class="col-2 px-0"
                @click.stop="() => showBalance = !showBalance"
              >
                <span v-if="!showBalance">
                  XXX.XX
                </span>

                <span v-else>{{ formatAmount(family.balance ? family.balance : 0) }}</span>
                <feather-icon
                  class="ml-1 cursor-pointer"
                  :icon="showBalance ? 'EyeOffIcon' : 'EyeIcon'"
                />
              </div>

              <!-- Actions -->
              <div class="col-1 text-right pr-2">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none p-0"
                  no-caret
                  right
                >
                  <!-- "More" Icon -->
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="24"
                    />
                  </template>
                  <!-- Edit Wallet Alias -->
                  <b-dropdown-item
                    @click.stop="showModal(family, 'edit-fam-details-modal')"
                  >
                    <i class="fa fa-edit mr-1" />
                    {{ $t('Rename') }}
                  </b-dropdown-item>

                  <!-- View Family Statement -->
                  <b-dropdown-item
                    @click.stop="$router.push({ name: 'wallet-statement', params: { userType, walletFamily: family.uuid} })"
                  >
                    <i class="fa fa-eye mr-1" />
                    {{ $t('View Statement') }}
                  </b-dropdown-item>

                  <!-- Add New Open Wallet -->
                  <b-dropdown-item
                    v-if="family.familyType === 'OPENWALLET'"
                    @click.stop="showModal(family, 'add-new-wallet-modal')"
                  >
                    <i class="fa fa-plus mr-1" />
                    {{ $t('Add New Account') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <!-- Alt Accounts -->
            <b-collapse :visible="detailsShowingFamily === family.uuid">
              <alt-accounts
                :index="index"
                :user-type="userType"
                :wallet="family"
                :accounts="family.shares"
                :family-type="family.familyType"
                :used-banks="usedBanks"
                :wallet-family="allWalletFamily"
                :is-default="family.isDefault"
                :show-balance="showBalance"
                @fetchWallet="selectedWallet => getWallet(selectedWallet)"
                @walletUpdated="onWalletUpdated"
                @balance-toggle="showBalance = !showBalance"
              />
            </b-collapse>
          </div>
        </div>

        <!-- Desktop View with Draggable -->
        <draggable
          v-else-if="walletFamily.length"
          v-model="walletFamily"
          @end="orderWalletFamily"
        >
          <div
            v-for="family, index in walletFamily"
            :key="family.uuid"
            class="cursor-move"
          >
            <div
              class="d-flex align-items-center cursor-pointer table-row"
              :class="{striped: index%2}"
              @mouseenter="hoveredFamily = family.uuid"
              @mouseleave="hoveredFamily = null"
              @click="detailsShowingFamily = detailsShowingFamily === family.uuid ? null : family.uuid"
            >
              <!-- Alias -->
              <div class="col-5 d-flex align-items-center ml-1 py-2">
                <!-- Drag Icon -->
                <div class="drag-btn pr-1">
                  <i
                    v-if="hoveredFamily === family.uuid && walletFamily.length > 1"
                    class="fas fa-grip-vertical cursor-move"
                  />
                </div>
                <!-- Alias Content -->
                <i
                  v-if="family.isDefault"
                  v-b-tooltip.hover="$t('Payroll Accounts')"
                  class="fa fa-cash-register"
                />
                <div>
                  <span class="px-1">{{ family.description }}</span>
                  <feather-icon
                    v-if="detailsShowingFamily === family.uuid"
                    size="20"
                    icon="ChevronUpIcon"
                  />
                  <feather-icon
                    v-else
                    size="20"
                    icon="ChevronDownIcon"
                  />
                  <!-- Shared Wallet Info -->
                  <div
                    v-if="family.shares.length && family.shares[0].owner && isWalletShared(family.shares[0], userType)"
                    class="ml-1"
                  >
                    <feather-icon icon="LinkIcon" />
                    <small class="small">
                      {{ $t('Shared by') }}
                      <i
                        class="ml-2"
                        :class="family.shares.length && family.shares[0].owner.__typename === 'User' ? 'fas fa-user' : 'fas fa-building'"
                      />
                      <span v-if="family.shares.length">
                        {{ family.shares[0].owner.name }}
                      </span>
                    </small>

                    <b-badge
                      variant="wz-teal"
                      class="d-block"
                    >
                      <i
                        class="fa"
                        :class="GET_SHARED_DIRECTION(family.shares[0].shareDirection, {icon: true})"
                      />
                      {{ family.shares[0].shareDirection ? GET_SHARED_DIRECTION(family.shares[0].shareDirection) : '' }}
                    </b-badge>
                  </div>
                </div>
              </div>

              <!-- Wallet Type -->
              <div class="col-4 px-0">
                <span v-if="isWalletShared(family, userType, 'family')">
                  {{ $t('Shared Account') }}
                </span>
                <span v-else>
                  {{ family.familyType === 'OPENWALLET' ? $t('Open Account') : $t('Percent Based Account') }}
                </span>
              </div>

              <!-- Total Balance -->
              <div
                class="col-2 px-0"
                @click.stop="() => showBalance = !showBalance"
              >
                <span v-if="!showBalance">
                  XXX.XX
                </span>

                <span v-else>{{ formatAmount(family.balance ? family.balance : 0) }}</span>
                <feather-icon
                  class="ml-1 cursor-pointer"
                  :icon="showBalance ? 'EyeOffIcon' : 'EyeIcon'"
                />
              </div>

              <!-- Actions -->
              <div class="col-1 text-right pr-2">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none p-0"
                  no-caret
                  right
                >
                  <!-- "More" Icon -->
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="24"
                    />
                  </template>
                  <!-- Edit Wallet Alias -->
                  <b-dropdown-item
                    @click.stop="showModal(family, 'edit-fam-details-modal')"
                  >
                    <i class="fa fa-edit mr-1" />
                    {{ $t('Rename') }}
                  </b-dropdown-item>

                  <!-- View Family Statement -->
                  <b-dropdown-item
                    @click.stop="$router.push({ name: 'wallet-statement', params: { userType, walletFamily: family.uuid} })"
                  >
                    <i class="fa fa-eye mr-1" />
                    {{ $t('View Statement') }}
                  </b-dropdown-item>

                  <!-- Add New Open Wallet -->
                  <b-dropdown-item
                    v-if="family.familyType === 'OPENWALLET'"
                    @click.stop="showModal(family, 'add-new-wallet-modal')"
                  >
                    <i class="fa fa-plus mr-1" />
                    {{ $t('Add New Account') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <!-- Alt Accounts -->
            <b-collapse :visible="detailsShowingFamily === family.uuid">
              <alt-accounts
                :index="index"
                :user-type="userType"
                :wallet="family"
                :accounts="family.shares"
                :family-type="family.familyType"
                :used-banks="usedBanks"
                :wallet-family="allWalletFamily"
                :is-default="family.isDefault"
                :show-balance="showBalance"
                @fetchWallet="selectedWallet => getWallet(selectedWallet)"
                @walletUpdated="onWalletUpdated"
                @balance-toggle="showBalance = !showBalance"
              />
            </b-collapse>
          </div>
        </draggable>

        <div v-else>
          <p class="striped text-center py-1">
            {{ $t('messages.no-accounts') }}
          </p>
        </div>
      </b-overlay>
    </div>

    <!-- Edit Wallet Family Alias -->
    <b-modal
      v-if="selectedWalletFam"
      id="edit-fam-details-modal"
      :title="$t('Rename Account')"
      hide-footer
      no-close-on-backdrop
    >
      <edit-wallet-alias
        :wallet-fam="selectedWalletFam"
        :user-type="userType"
        wallet-type="family"
        :no-title="true"
        @cancel="() => { $bvModal.hide('edit-fam-details-modal')}"
        @wallet-updated="alias => onWalletFamilyUpdated(alias)"
      />
    </b-modal>

    <!-- Add New Open Wallet -->
    <add-new-open-wallet
      v-if="selectedWalletFam"
      :user-type="userType"
      :used-banks="usedBanks"
      :wallet="selectedWalletFam"
      @wallet-added="wallet => { getWallet(wallet) }"
    />
  </b-overlay>
</template>

<script>
import {
  BOverlay, BButton, VBTooltip, BBadge, BDropdown, BDropdownItem, BCollapse,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import EventBus from '@/event-bus'
import { getUserData } from '@/auth/utils'
import useWallet from '@/utils/wallet'
import draggable from 'vuedraggable'
import AltAccounts from './AltAccounts.vue'
import CreateSplitWallet from './WalletActions/CreateSplitWallet.vue'
import EditWalletAlias from './WalletActions/EditWalletAlias.vue'
import AddNewOpenWallet from './WalletActions/AddNewOpenWallet.vue'

const { isWalletShared, GET_SHARED_DIRECTION } = useWallet()

export default {
  components: {
    BOverlay,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BCollapse,
    draggable,
    AltAccounts,
    CreateSplitWallet,
    EditWalletAlias,
    AddNewOpenWallet,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    userType: {
      type: String,
      default: () => 'users',
    },
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isWalletShared,
      GET_SHARED_DIRECTION,
      self: getUserData(),
      walletFamily: [],
      fields: [
        { key: 'alias', label: 'Alias', classes: 'col-5 py-1 pl-4' },
        { key: 'type', label: 'Type', classes: 'col-4' },
        { key: 'totalBalance', label: 'Total Balance', classes: 'col-2' },
        { key: 'actions', classes: 'col-1' },
      ],
      familyTypes: [
        { text: 'Percentage Based Accounts', value: 'PERCENTBASED' },
        { text: 'Open Accounts', value: 'OPENWALLET' },
      ],
      wallet: {},
      accounts: [],
      usedBanks: [],
      isLoading: false,
      currentFam: null,
      selectedWalletFam: null,
      showBalance: false,
      totalBalance: 0,
      payableBalance: 0,
      createWallet: false,
      familyTypeToCreate: '',
      detailsShowingFamily: null,
      hoveredFamily: null,
      showingDropdown: false,
    }
  },
  computed: {
    allWalletFamily() {
      return this.walletFamily.map(({
        uuid, description, shares, familyType,
      }) => ({
        uuid,
        description,
        shares,
        familyType,
      }))
    },
  },
  mounted() {
    this.getWallet()
    EventBus.$on('wallet-share-approved', () => { this.getWallet() })
    EventBus.$on('fetch-wallets', () => { this.getWallet() })
  },
  methods: {
    orderWalletFamily({ oldIndex, newIndex }) {
      if (oldIndex !== newIndex) {
        let api = 'updateUserWalletOrders'
        const orderedWalletUids = this.walletFamily.map(family => family.uuid)

        const params = {
          orderedWalletUids,
        }

        if (this.userType === 'company') {
          api = 'updateCompanyWalletOrders'
          params.companyUid = this.company.uuid
        }

        useApollo[this.userType][api](params).then(response => {
          this.showSuccessMessage({
            data: {
              message: response.data[api].message,
            },
          })
        }).catch(error => {
          this.showErrorMessage(error)
        })
      }
    },
    getWallet(selectedWallet = null) {
      let api = 'getUserWallet'
      const params = {}
      let res = 'me'
      if (this.userType === 'company') {
        api = 'getCompanyWallet'
        params.company = this.company.uuid
        res = 'company'
      }

      this.isLoading = true
      useApollo[this.userType][api](params).then(response => {
        const data = response.data[res]
        this.totalBalance = data?.walletBalance
        this.payableBalance = data?.realBalance
        this.walletFamily = data?.wallets.data

        if (this.walletFamily.length) {
          this.detailsShowingFamily = selectedWallet ? selectedWallet.uuid : this.walletFamily[0].uuid
        }

        this.usedBanks = this.walletFamily.flatMap(family => family.shares).filter(share => share.wallet.__typename === 'BankWallet').map(bankWallet => ({
          id: bankWallet.wallet.information.id,
          alias: bankWallet.wallet.information.alias,
        }))
      }).finally(() => { this.isLoading = false })
    },
    onWalletFamilyUpdated(alias) {
      this.walletFamily.find(fam => fam.uuid === this.selectedWalletFam.uuid).description = alias
      this.$bvModal.hide('edit-fam-details-modal')
      this.selectedWalletFam = null
    },
    onWalletUpdated(family, wallet, alias) {
      this.walletFamily.find(fam => fam.uuid === family).shares.find(share => share.uuid === wallet).description = alias
    },
    showModal(wallet, modal) {
      this.selectedWalletFam = wallet
      this.$nextTick(() => {
        this.$bvModal.show(modal)
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/wallet-table.scss";
</style>
